import './Style.css';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HeaderPage from './components/HeaderPage';
import FooterPage from './components/FooterPage';
import ScrollToTop from './components/ScrollTop';
import FormBottom from './components/FormBottom';
import HomePage from './components/HomePage';
const AboutPage = React.lazy(() => import('./components/AboutPage'));
const ServicesPage = React.lazy(() => import('./components/ServicesPage'));
const SearvicesProviderPage = React.lazy(() => import('./components/SearvicesProviderPage'));
const Searvices = React.lazy(() => import('./components/Searvices'));
const BlogPage = React.lazy(() => import('./components/BlogPage'));
const BlogContent = React.lazy(() => import('./components/BlogContent'));
const CareerPage = React.lazy(() => import('./components/CareerPage'));
const ContactPage = React.lazy(() => import('./components/ContactPage'));
const ErrorPage = React.lazy(() => import('./components/ErrorPage'));

function App() {

  return (
    <>
      <Router>
        <HeaderPage />
        <ScrollToTop />
          <Suspense fallback={<div className="loader"></div>}>
        <div id="wrap">
          <div className="lqd-sticky-placeholder hidden"></div>
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            {/* <Route exact path="/test" element={<Test />} /> */}
            <Route exact path="/about" element={<AboutPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/services/:title" element={<Searvices />} />
            <Route path="/services/:service/:serviceprovider" element={<SearvicesProviderPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog/category/:category" element={<BlogPage />} />
            {/* <Route path="/blog/:blogcontent" element={<BlogContent />} /> */}
            <Route path="/:blogcontent" element={<BlogContent />} />
            <Route path="/career" element={<CareerPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/404" element={<ErrorPage/>} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </div>
        <FooterPage />
        <FormBottom />
          </Suspense>
      </Router>
    </>
  );
}

export default App;