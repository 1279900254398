import React, { useEffect, } from "react";
// import HeaderPage from './HeaderPage';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { homeContentSec, lineAnimate, subHeadHead } from "../functions/functions";
import GetStarted from "./GetStarted";
import Particle from "./Particle";
import Throwable from "./Throwable";
import RotateText from "./RotateText";
import Marquee from 'react-fast-marquee';
import { Link } from "react-router-dom";
// import FooterPage from "./FooterPage";
import { metaData } from "../metaData/meta";
import MetaData from "./MetaData";
import { blog } from "../data/blogs"
import { generateURL } from "../helpers/generateUrl";

export default function HomePage() {

    function filterBlogsByDate(blog) {
        const currentDate = new Date();
        return blog.filter(blog => {
            const blogDate = new Date(blog.created_at);
            return blogDate <= currentDate;
        });
    }
    
    const blogs = filterBlogsByDate(blog);
    const lastThreeBlogs = blogs.slice(-3);

    function truncateTitle(title) {
        return title.length > 70 ? title.substring(0, 70) + "..." : title;
    }

    // useEffect(() => {
    //     gsap.registerPlugin(ScrollTrigger);
    // }, [])

    // useEffect(() => {
    //     lineAnimate('line-animate', 1)
    //     gsap.fromTo('.ld_fancy_animate h1,.ld_fancy_animate h6', { y: 70, rotationY: 70, opacity: 0 }, { ease: 'power4.out', duration: 2, y: 0, rotationY: 0, opacity: 1 });
    // }, [])

    // useEffect(() => {
    //     gsap.timeline({
    //         scrollTrigger: {
    //             trigger: ".home-form-animate",
    //             start: "top 90%",
    //         },
    //     }).fromTo(".home-form-animate h2,.home-form-animate .animation-element", { y: 60, scaleX: 0.8, scaleY: 0.8, opacity: 0 }, { duration: 2, ease: 'expo.out', y: 0, scaleX: 1, scaleY: 1, opacity: 1 });
    // }, [])

    // useEffect(() => {
    //     subHeadHead(".subhead-head")
    //     subHeadHead(".subhead-head0")
    //     subHeadHead(".subhead-head1")
    //     homeContentSec(".animation-element")
    //     homeContentSec(".animation-element0")
    //     homeContentSec(".animation-element1")
    //     homeContentSec(".animation-element2")
    //     homeContentSec(".animation-element3")
    // }, []);

    // useEffect(() => {
    //     gsap.timeline({
    //         scrollTrigger: {
    //             trigger: ".iconbox-icon-animate",
    //             start: "top 80%",
    //         },
    //     }).fromTo('.iconbox-icon-animate', { y: 50 }, { y: 0, stagger: 0.1, duration: 1.5, ease: "power3.out", });
    // }, [])

    // useEffect(() => {
    //     gsap.timeline({
    //         scrollTrigger: {
    //             trigger: ".contents",
    //             start: "top 80%",
    //         },
    //     }).fromTo('.contents h6,.contents h2,.contents p', { y: '45px', rotationY: 65, opacity: 0, transformOrigin: '100% 50% 0px' }, { stagger: 0.1, duration: 1.5, ease: "power3.out", y: '0px', rotationY: 0, opacity: 1, transformOrigin: '50% 50% 80px' });
    // }, [])

     // Register gsap plugins and create animations
     useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        lineAnimate('line-animate', 1);

        gsap.fromTo('.ld_fancy_animate h1, .ld_fancy_animate h6', 
            { y: 70, rotationY: 70, opacity: 0 }, 
            { ease: 'power4.out', duration: 2, y: 0, rotationY: 0, opacity: 1 });

        gsap.timeline({
            scrollTrigger: {
                trigger: ".home-form-animate",
                start: "top 90%",
            },
        }).fromTo(".home-form-animate h2, .home-form-animate .animation-element", 
            { y: 60, scaleX: 0.8, scaleY: 0.8, opacity: 0 }, 
            { duration: 2, ease: 'expo.out', y: 0, scaleX: 1, scaleY: 1, opacity: 1 });

        subHeadHead(".subhead-head");
        subHeadHead(".subhead-head0");
        subHeadHead(".subhead-head1");
        homeContentSec(".animation-element");
        homeContentSec(".animation-element0");
        homeContentSec(".animation-element1");
        homeContentSec(".animation-element2");
        homeContentSec(".animation-element3");

        gsap.timeline({
            scrollTrigger: {
                trigger: ".iconbox-icon-animate",
                start: "top 80%",
            },
        }).fromTo('.iconbox-icon-animate', 
            { y: 50 }, 
            { y: 0, stagger: 0.1, duration: 1.5, ease: "power3.out" });

        gsap.timeline({
            scrollTrigger: {
                trigger: ".contents",
                start: "top 80%",
            },
        }).fromTo('.contents h6, .contents h2, .contents p', 
            { y: '45px', rotationY: 65, opacity: 0, transformOrigin: '100% 50% 0px' }, 
            { stagger: 0.1, duration: 1.5, ease: "power3.out", y: '0px', rotationY: 0, opacity: 1, transformOrigin: '50% 50% 80px' });
    }, []);

    return (
        <>
            <MetaData metaData={metaData["homepage"]} />
            {/* <HeaderPage/> */}
            <main className="content bg-top-center bg-cover  index-background-image" id="lqd-site-content">
                <div id="lqd-contents-wrap">
                    
                    {/* front page */}
                    <section className="lqd-section banner min-h-100vh w-full flex flex-column items-center justify-center" id="banner">
                        <div className="absolute w-full h-full">
                            <div className="ld-particles-container relative w-full lqd-particles-as-bg lqd-overlay flex ">
                                <div className="ld-particles-inner lqd-overlay flex pointer-events-none" id="lqd-particle-banner">
                                    <Particle />
                                </div>
                            </div>
                        </div>

                        <div className="lqd-shape lqd-shape-bottom" data-negative="false">
                            <svg className="lqd-custom-shape" fill="none" height="461" viewBox="0 0 1440 461" width="1440" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                                <path className="lqd-shape-fill" fill="#f5f5f5" fillOpacity="1" d="M0 154.75L34.4 142.201C68.8 129.53 137.6 104.433 206.08 99.072C274.72 93.833 342.88 108.453 411.52 122.099C480 135.622 548.8 148.293 617.28 142.811C685.92 137.329 754.08 113.693 822.72 113.693C891.2 113.693 960 137.329 1028.48 152.68C1097.12 168.153 1165.28 175.463 1233.92 184.966C1302.4 194.591 1371.2 206.287 1405.6 212.257L1440 218.105V452.025H0L0 154.75Z">
                                    <animate repeatCount="indefinite" fill="freeze" attributeName="d" dur="8s" values="M0 154.75C0 154.75 12.8 142.902 34.4 142.201C56 141.5 140.02 160.111 208.5 154.75C277.14 149.511 334.36 112.57 403 126.216C471.48 139.739 552.52 190.448 621 184.966C689.64 179.484 745.36 116 814 116C882.48 116 950.52 161.149 1019 176.5C1087.64 191.973 1154.36 123.997 1223 133.5C1291.48 143.125 1371.2 206.287 1405.6 212.257L1440 218.105V452.025H0L0 154.75Z; M0 154.75C0 154.75 33.4 177.201 55 176.5C76.6 175.799 137.52 110.361 206 105C274.64 99.761 332.86 141.104 401.5 154.75C469.98 168.273 527.52 206.982 596 201.5C664.64 196.018 747.86 75 816.5 75C884.98 75 956.52 118.149 1025 133.5C1093.64 148.973 1163.36 87.497 1232 97C1300.48 106.625 1371.2 206.287 1405.6 212.257L1440 218.105V452.025H0L0 154.75Z; M0 154.75C0 154.75 12.8 142.902 34.4 142.201C56 141.5 140.02 160.111 208.5 154.75C277.14 149.511 334.36 112.57 403 126.216C471.48 139.739 552.52 190.448 621 184.966C689.64 179.484 745.36 116 814 116C882.48 116 950.52 161.149 1019 176.5C1087.64 191.973 1154.36 123.997 1223 133.5C1291.48 143.125 1371.2 206.287 1405.6 212.257L1440 218.105V452.025H0L0 154.75Z"></animate>
                                </path>
                                <path className="lqd-shape-fill" fill="#f9cfaf" fillOpacity="1" d="M0 340.22L34.4 333.92C68.8 327.52 137.6 314.92 206.08 312.22C274.72 309.52 342.88 316.92 411.52 319.72C480 322.52 548.8 320.92 617.28 318.92C685.92 316.92 754.08 314.52 822.72 316.02C891.2 317.52 960 322.92 1028.48 309.42C1097.12 295.92 1165.28 263.52 1233.92 251.02C1302.4 238.52 1371.2 245.92 1405.6 249.52L1440 253.22V453.22H0L0 340.22Z">
                                    <animate repeatCount="indefinite" fill="freeze" attributeName="d" dur="6.5s" values="M0 340.22L34.4 333.92C68.8 327.52 139.02 281.2 207.5 278.5C276.14 275.8 351.86 331.12 420.5 333.92C488.98 336.72 554.52 289 623 287C691.64 285 756.86 332.42 825.5 333.92C893.98 335.42 960 322.92 1028.48 309.42C1097.12 295.92 1163.36 236 1232 223.5C1300.48 211 1371.2 245.92 1405.6 249.52L1440 253.22V453.22H0L0 340.22Z; M0 340.22L37.5 323C71.9 316.6 137.52 336.62 206 333.92C274.64 331.22 339.86 272.2 408.5 275C476.98 277.8 551.02 304 619.5 302C688.14 300 759.36 266.5 828 268C896.48 269.5 962.02 336.5 1030.5 323C1099.14 309.5 1156.36 232.5 1225 220C1293.48 207.5 1364.1 249.62 1398.5 253.22L1440 253.22V453.22H0L0 340.22Z; M0 340.22L34.4 333.92C68.8 327.52 139.02 281.2 207.5 278.5C276.14 275.8 351.86 331.12 420.5 333.92C488.98 336.72 554.52 289 623 287C691.64 285 756.86 332.42 825.5 333.92C893.98 335.42 960 322.92 1028.48 309.42C1097.12 295.92 1163.36 236 1232 223.5C1300.48 211 1371.2 245.92 1405.6 249.52L1440 253.22V453.22H0L0 340.22Z"></animate>
                                </path>
                            </svg>
                        </div>
                        <div className="container">
                            <div className="row items-center">
                                <div className="col col-12 col-xl-6">
                                    <div className="ld_fancy_animate">
                                        <div style={{ perspective: '800px' }} className="ld-fancy-heading relative">
                                            <h6 className="ld-fh-element inline-block relative mb-2em text-12 uppercase font-bold tracking-1/5 text-gray-900 text-manual-primary pl-10">Creative solutions for everyone</h6>
                                        </div>
                                        <div style={{ perspective: '800px' }} className="ld-fancy-heading relative">
                                            <h1 className="ld-fh-element inline-block relative text-rotator-activated mb-0/25em text-72 leading-1/05em tracking-0 lqd-unit-animation-done">Custom&nbsp;
                                                <span className="txt-rotate-keywords custom-w-113">
                                                    <span className="custom-flip-texts ld-gradient-heading bg-transparent txt-rotate-wraper">
                                                        <span className="txt-rotate-keywords" style={{ minWidth: '340px' }}>
                                                            <RotateText rotateKeyword={['Software', 'Web',]} />
                                                        </span>
                                                    </span>
                                                </span>
                                                <span className="d-block-1199">Development </span>Company
                                            </h1>
                                        </div>
                                        <div className="w-full">
                                            <div className="ld-fancy-heading relative mask-text module-text">
                                                <p className="line-animate ld-fh-element mb-0/5em inline-block relative text-20 leading-1/5em lqd-split-lines">Bring your business to new heights with our flexible web and software development solutions, tailored for growth.</p>
                                            </div>
                                        </div>
                                        <div className="flex flex-row flex-wrap items-center justify-start">
                                            <Link to="/contact" data-name="Get a Quote" className="button-custom"></Link>
                                            <div className="fancy-button mr-15 module-btn sm:w-full"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-xl-6 sm:order-first">
                                    <div className="image-container relative -mr-50 lg:m-0">
                                        <div className="ld-masked-image" data-dynamic-shape="true">
                                            <svg className="scene lqd-overlay flex" viewBox="140 140 140 140">
                                                <defs>
                                                    <clipPath id="banner-masked-svg" clipPathUnits="objectBoundingBox" transform="scale(0.00158)">
                                                        <path vectorEffect="non-scaling-stroke" fill="black"
                                                            d="M212.625, 0.091 C319.112, -2.992 719.225, 71.583 615.507, 328.179 C511.789, 584.775 250.263, 624.292 112.94, 568.057 C-24.383, 511.822 -12.023, 229.89 23.583, 138.127 C59.189, 46.364 106.138, 3.174 212.625, 0.091 Z"
                                                            pathdataid="M362.5, 4 C487, 4 631, -44 631, 201.5 C631, 447 538, 623.5 310.5, 581.5 C83, 539.5 -29.917, 266.627 7, 156.5 C43.917, 46.373 238, 4 362.5, 4 Z;M370, 18 C494.5, 18 627, -56.5 627, 189 C627, 434.5 405.5, 573.5 155.5, 581 C-94.5, 588.5 23.083, 175.127 60, 65 C96.917, -45.127 245.5, 18 370, 18 Z">
                                                        </path>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <figure className="clip-svg">
                                                <img width="1292" height="1196" src="/assets/images/home/home.svg" className="w-full" alt="BannerImage" />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* custom services section start */}
                    <section className="lqd-section about pt-85 pb-15 .custom-service-background bg-top-center bg-cover transition-all bg-no-repeat" id="about">
                        <div className="container">
                            <div className="row text-center justify-center">
                                <div className="col col-12 col-md-8 col-lg-6 mb-0 text-center relative p-0 module-title">
                                    <div className="ld-fancy-heading relative">
                                        <h2 className="subhead-head ld-fh-element m-0 inline-block relative">We Provide Best Services</h2>
                                    </div>
                                    <div className="ld-fancy-heading relative">
                                        <h2 className="subhead-head ld-fh-element custom-flip-texts  relative ld-gradient-heading bg-transparent leading-1/2em mb-0/5em">
                                            <span className="txt-rotate-keywords mn-h-88">
                                                <RotateText rotateKeyword={['Web Development', 'App Development', 'Digital Marketing', 'Graphic Designing',]} />
                                            </span>
                                        </h2>
                                    </div>
                                    <p className="subhead-head"><span
                                        className="text-16 font-medium leading-1/2em text-blue-400">Explore our array of
                                        services tailored to meet your needs, delivering excellence and innovation every
                                        step of the way.</span></p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="lqd-section what-we-do my-50 custom-services-space" id="what-we-do">
                        <div className="container">
                            <div className="row items-center">
                                <div className="col col-12 col-md-5 col-xl-5 p-0 flex flex-row flex-wrap justify-center items-center ca-initvalues-applied lqd-animations-done">
                                    <div className="lqd-imggrp-single block relative perspective hover-3d-applied lqd-unit-animation-done" data-float="ease-in">
                                        <div
                                            className="lqd-imggrp-img-container inline-flex relative items-center justify-center transform-style-3d">
                                            <figure className="w-full relative" data-stacking-factor="1"><img width="492" height="596" src="/assets/images/home/web_dev.svg" alt="Web Development" /></figure>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-xl-7 col-md-7 p-0">
                                    <div className="w-full flex flex-col items-start pr-5percent pl-10percent module-content">
                                        <div className="ld-fancy-heading relative w-auto mb-0/65em bg-orange-100 rounded-100 animation-element0">
                                            <h6 className="ld-fh-element relative m-0 py-10 px-20">Services</h6>
                                        </div>
                                        <div className="ld-fancy-heading relative animation-element0 lqd-unit-animation-done">
                                            <h3 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h3">
                                                <span>Web </span>
                                                <mark className="lqd-highlight">
                                                    <span className="lqd-highlight-txt">Development</span>
                                                    <span className="lqd-highlight-inner"></span>
                                                </mark>
                                            </h3>
                                        </div>
                                        <div className="ld-fancy-heading pb-0/5em relative animation-element0">
                                            <p className="ld-fh-element mb-1/25em inline-block relative lqd-highlight-classic lqd-highlight-grow-left">We're all about providing top-notch Web Development services that help all kinds of businesses, big or small, succeed online. Our team is dedicated and skilled, using various tools and technologies to meet your website needs, whether they're simple or complex. We take a unique approach to make sure your website stands out, giving you a strong online presence. Plus, we've got years of experience working with popular platforms like PHP, Shopify, Laravel, CodeIgniter, and WordPress, so you're in good hands. These tools help us create websites that are not just pretty but also functional and interactive.</p>
                                        </div>
                                        <Link to="/services/web-development" data-name="View More" className="button-custom"></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="lqd-section what-we-do my-50" id="what-we-do">
                        <div className="container">
                            <div className="row items-center items-centers">
                                <div className="col col-12 col-xl-7 col-md-7 p-0 order-rev">
                                    <div className="w-full flex flex-col items-start pr-5percent pl-10percent module-content">
                                        <div
                                            className="ld-fancy-heading relative w-auto mb-0/65em bg-orange-100 rounded-100 animation-element1">
                                            <h6 className="ld-fh-element relative m-0 py-10 px-20">Services</h6>
                                        </div>
                                        <div className="ld-fancy-heading relative animation-element1 lqd-unit-animation-done">
                                            <h3 className="mb-0/75em ld-fh-element relative lqd-highlight-classic  lqd-highlight-reset-onhover h3">
                                                <span>Android App </span>
                                                <mark className="lqd-highlight">
                                                    <span className="lqd-highlight-txt">Development</span>
                                                    <span className="lqd-highlight-inner"></span>
                                                </mark>
                                            </h3>
                                        </div>
                                        <div className="ld-fancy-heading pb-0/5em relative animation-element1">
                                            <p className="ld-fh-element mb-1/25em inline-block relative lqd-highlight-classic lqd-highlight-grow-left">We specialize in creating innovative and user-friendly Android apps that meet your business needs. With extensive experience and expertise, our team delivers high-performance, visually appealing apps from concept to deployment. Whether you need a simple app or a complex solution, we ensure your vision comes to life with cutting-edge technology and seamless functionality. Trust Clevero Technology for exceptional Android app development that drives engagement and achieves your goals.</p>
                                        </div>
                                        <Link to="/services/android-development" data-name="View More" className="button-custom"></Link>
                                    </div>
                                </div>
                                <div className="col col-12  col-xl-5 col-md-5 p-0 flex flex-row flex-wrap justify-center items-center ca-initvalues-applied lqd-animations-done">
                                    <div className="lqd-imggrp-single block relative perspective hover-3d-applied lqd-unit-animation-done" data-hover3d="true" data-float="ease-in">
                                        <div
                                            className="lqd-imggrp-img-container inline-flex relative pr-10percent pl-10percent items-center justify-center transform-style-3d">
                                            <figure className="w-full relative" data-stacking-factor="1"><img width="700"
                                                height="800" src=" ../assets/images/home/app-dev.svg"
                                                alt="3D shape marketing" />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="lqd-section what-we-do my-50 custom-services-space" id="what-we-do">
                        <div className="container">
                            <div className="row items-center">
                                <div className="col col-12 col-md-5 col-xl-5 p-0 flex flex-row flex-wrap justify-center items-center ca-initvalues-applied lqd-animations-done">
                                    <div className="lqd-imggrp-single block relative perspective hover-3d-applied lqd-unit-animation-done"
                                        data-hover3d="true" data-float="ease-in">
                                        <div
                                            className="lqd-imggrp-img-container inline-flex relative items-center justify-center transform-style-3d">
                                            <figure className="w-full relative" data-stacking-factor="1"><img width="492"
                                                height="596" src="/assets/images/home/digital-market.svg"
                                                alt="3D shape marketing" />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-xl-7 col-md-7 p-0">
                                    <div className="w-full flex flex-col items-start pr-5percent pl-10percent module-content">
                                        <div
                                            className="ld-fancy-heading relative w-auto mb-0/65em bg-orange-100 rounded-100 animation-element2">
                                            <h6 className="ld-fh-element relative m-0 py-10 px-20">Services</h6>
                                        </div>
                                        <div className="ld-fancy-heading relative animation-element2 lqd-unit-animation-done">
                                            <h3 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h3">
                                                <span>Digital</span> <mark className="lqd-highlight"><span
                                                    className="lqd-highlight-txt">Marketing</span> <span
                                                        className="lqd-highlight-inner"></span></mark>
                                            </h3>
                                        </div>
                                        <div className="ld-fancy-heading pb-0/5em relative animation-element2">
                                            <p className="ld-fh-element mb-1/25em inline-block relative lqd-highlight-classic lqd-highlight-grow-left">Explore our comprehensive Digital Marketing Services designed to propel your brand's online presence to new heights. At <Link to="/"><strong className="text-primary"> Clevero Technology</strong></Link>, we specialise in crafting customised strategies tailored to meet your unique business objectives. From search engine optimization (SEO) and pay-per-click (PPC) advertising to social media management and content marketing, we offer a full suite of services to boost your digital footprint. Our team of seasoned professionals is dedicated to delivering measurable results that drive traffic, increase conversions, and maximise ROI. With a focus on innovation and strategic thinking, we're committed to helping you stay ahead in today's competitive landscape.</p>
                                        </div>
                                        <Link to="/services/digital-marketing" data-name="View More" className="button-custom"></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="lqd-section what-we-do my-50 " id="what-we-do">
                        <div className="container">
                            <div className="row items-center items-centers">
                                <div className="col col-12 col-xl-7 col-md-7 order-rev p-0">
                                    <div className="w-full flex flex-col items-start pr-5percent pl-10percent module-content">
                                        <div
                                            className="ld-fancy-heading relative w-auto mb-0/65em bg-orange-100 rounded-100 animation-element3">
                                            <h6 className="ld-fh-element relative m-0 py-10 px-20">Services</h6>
                                        </div>
                                        <div className="ld-fancy-heading relative animation-element3 lqd-unit-animation-done">
                                            <h3 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h3">
                                                <span>Graphic</span> <mark className="lqd-highlight"><span
                                                    className="lqd-highlight-txt">Designing</span> <span
                                                        className="lqd-highlight-inner"></span></mark>
                                            </h3>
                                        </div>
                                        <div className="ld-fancy-heading pb-0/5em relative animation-element3">
                                            <p className="ld-fh-element mb-1/25em inline-block relative lqd-highlight-classic lqd-highlight-grow-left"> Graphic designing services encompass a wide range of creative solutions aimed at visually communicating ideas, messages, or concepts. These services typically include the creation of logos, branding materials, marketing collateral, website graphics, social media content, advertisements, packaging designs, and more. Graphic designers use various tools and software to manipulate images, typography, and layout to produce visually appealing and effective designs that meet the needs and objectives of their clients. Whether it's for print or digital media, graphic design services play a crucial role in shaping the identity and perception of businesses, products, and services in the marketplace.</p>
                                        </div>
                                        <Link to="/services/graphic-designing" data-name="View More" className="button-custom"></Link>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-5 col-xl-5  p-0 flex flex-row flex-wrap justify-center items-center ca-initvalues-applied lqd-animations-done">
                                    <div className="lqd-imggrp-single block relative perspective hover-3d-applied lqd-unit-animation-done"
                                        data-hover3d="true" data-float="ease-in">
                                        <div
                                            className="lqd-imggrp-img-container inline-flex relative items-center justify-center transform-style-3d">
                                            <figure className="w-full relative" data-stacking-factor="1"><img width="492"
                                                height="596" src="/assets/images/home/graphic-design-updated.svg"
                                                alt="3D shape marketing" />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="lqd-section services pt-45 pb-55 bg-transparent why-background-2" id="services">
                        <div className="background-overlay bg-transparent transition-all opacity-100 why-background-2"></div>
                        <div className="container">
                            <div className="row justify-center">
                                <div className="col col-12 col-md-10 col-lg-9 col-xl-7 mb-25 text-center px-70 sm:px-0">
                                    <div className="ld-fancy-heading relative">
                                        <div className="w-full flex flex-col items-center text-center">
                                            <div
                                                className="ld-fancy-heading relative w-auto mb-0/65em bg-orange-100 rounded-100 animation-element">
                                                <h6 className="subhead-head0 ld-fh-element relative  m-0 py-10 px-20">Advantages
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="ld-fancy-heading relative animation-element lqd-unit-animation-done">
                                            <h2 className="subhead-head0 mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2">
                                                <span>Why Choose </span> <mark className="lqd-highlight"><span
                                                    className="lqd-highlight-txt">Us </span> <span
                                                        className="lqd-highlight-inner"></span></mark>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 p-0"></div>
                                <div className="col col-10 col-md-6 p-15  col-xl-3 iconbox-icon-animate">
                                    <div
                                        className="iconbox flex flex-grow-1 flex-wrap items-center relative iconbox-heading-arrow-onhover box-shadow-bottom relative bg-white rounded-16 pt-35 justify-center pb-40 pr-50 pl-65 mb-15 ml-10 lg:m-0">
                                        <div className="iconbox-icon-wrap">
                                            <div className="text-34 iconbox-icon-container inline-flex mr-10">
                                                <img src="/assets/images/home/reliability-cost-1.png" width="50px" alt="" />
                                            </div>
                                        </div>
                                        <div className="iconbox-icon-wrap">
                                            <div className="text-34 iconbox-icon-container inline-flex">
                                                <h3 className="text-20 lqd-iconbox-heading"><span>Reliability Cost</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-10 col-md-6 p-15  col-xl-3 iconbox-icon-animate">
                                    <div
                                        className="iconbox flex flex-grow-1 flex-wrap items-center relative iconbox-heading-arrow-onhover box-shadow-bottom relative bg-white rounded-16 pt-35 justify-center pb-40 pr-50 pl-65 mb-15 ml-10 lg:m-0">
                                        <div className="iconbox-icon-wrap">
                                            <div className="text-34 iconbox-icon-container inline-flex mr-10">
                                                <img src="/assets/images/home/trusted-team-2.png" width="50px" alt="" />
                                            </div>
                                        </div>
                                        <div className="iconbox-icon-wrap">
                                            <div className="text-34 iconbox-icon-container inline-flex">
                                                <h3 className="text-20 lqd-iconbox-heading"><span>Trusted Team</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-10 col-md-6 p-15  col-xl-3 iconbox-icon-animate">
                                    <div
                                        className="iconbox flex flex-grow-1 flex-wrap items-center relative iconbox-heading-arrow-onhover box-shadow-bottom relative bg-white rounded-16 pt-35 justify-center pb-40 pr-50 pl-65 mb-15 ml-10 lg:m-0">
                                        <div className="iconbox-icon-wrap">
                                            <div className="text-34 iconbox-icon-container inline-flex mr-10">
                                                <img src="/assets/images/home/deadline-management-3.png" width="50px" alt="" />
                                            </div>
                                        </div>
                                        <div className="iconbox-icon-wrap">
                                            <div className="text-34 iconbox-icon-container inline-flex">
                                                <h3 className="text-20 lqd-iconbox-heading"><span>Deadline Management</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-10 col-md-6 p-15  col-xl-3 iconbox-icon-animate">
                                    <div
                                        className="iconbox flex flex-grow-1 flex-wrap items-center relative iconbox-heading-arrow-onhover box-shadow-bottom relative bg-white rounded-16 pt-35 justify-center pb-40 pr-50 pl-65 mb-15 ml-10 lg:m-0">
                                        <div className="iconbox-icon-wrap">
                                            <div className="text-34 iconbox-icon-container inline-flex mr-10">
                                                <img src="/assets/images/home/interactive-design-4.png" width="50px" alt="" />
                                            </div>
                                        </div>
                                        <div className="iconbox-icon-wrap">
                                            <div className="text-34 iconbox-icon-container inline-flex">
                                                <h3 className="text-20 lqd-iconbox-heading"><span>Interactive Design</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-10 col-md-6 p-15  col-xl-3 iconbox-icon-animate">
                                    <div
                                        className="iconbox flex flex-grow-1 flex-wrap items-center relative iconbox-heading-arrow-onhover box-shadow-bottom relative bg-white rounded-16 pt-35 justify-center pb-40 pr-50 pl-65 mb-15 ml-10 lg:m-0">
                                        <div className="iconbox-icon-wrap">
                                            <div className="text-34 iconbox-icon-container inline-flex mr-10">
                                                <img src="/assets/images/home/client-feedback-5.png" width="50px" alt="" />
                                            </div>
                                        </div>
                                        <div className="iconbox-icon-wrap">
                                            <div className="text-34 iconbox-icon-container inline-flex">
                                                <h3 className="text-20 lqd-iconbox-heading"><span>Client Feedback</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-10 col-md-6 p-15  col-xl-3 iconbox-icon-animate">
                                    <div
                                        className="iconbox flex flex-grow-1 flex-wrap items-center relative iconbox-heading-arrow-onhover box-shadow-bottom relative bg-white rounded-16 pt-35 justify-center pb-40 pr-50 pl-65 mb-15 ml-10 lg:m-0">
                                        <div className="iconbox-icon-wrap">
                                            <div className="text-34 iconbox-icon-container inline-flex mr-10">
                                                <img src="/assets/images/home/best-approach-6.png" width="50px" alt="" />
                                            </div>
                                        </div>
                                        <div className="iconbox-icon-wrap">
                                            <div className="text-34 iconbox-icon-container inline-flex">
                                                <h3 className="text-20 lqd-iconbox-heading"><span>Best Approach</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-10 col-md-6 p-15  col-xl-3 iconbox-icon-animate">
                                    <div
                                        className="iconbox flex flex-grow-1 flex-wrap items-center relative iconbox-heading-arrow-onhover box-shadow-bottom relative bg-white rounded-16 pt-35 justify-center pb-40 pr-50 pl-65 mb-15 ml-10 lg:m-0">
                                        <div className="iconbox-icon-wrap">
                                            <div className="text-34 iconbox-icon-container inline-flex mr-10">
                                                <img src="/assets/images/home/experience-team-7.png" width="50px" alt="" />
                                            </div>
                                        </div>
                                        <div className="iconbox-icon-wrap">
                                            <div className="text-34 iconbox-icon-container inline-flex">
                                                <h3 className="text-20 lqd-iconbox-heading"><span>Experience Team</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-10 col-md-6 p-15  col-xl-3 iconbox-icon-animate">
                                    <div
                                        className="iconbox flex flex-grow-1 flex-wrap items-center relative iconbox-heading-arrow-onhover box-shadow-bottom relative bg-white rounded-16 pt-35 justify-center pb-40 pr-50 pl-65 mb-15 ml-10 lg:m-0">
                                        <div className="iconbox-icon-wrap">
                                            <div className="text-34 iconbox-icon-container inline-flex mr-10">
                                                <img src="/assets/images/home/quality-assurance-8.png" width="50px" alt="" />
                                            </div>
                                        </div>
                                        <div className="iconbox-icon-wrap">
                                            <div className="text-34 iconbox-icon-container inline-flex">
                                                <h3 className="text-20 lqd-iconbox-heading"><span>Quality Assurance</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section className="lqd-section throwable flex flex-col flex-grow-0 flex-shrink-1 items-center transition-all rounded-30 shadow-md bg-white pt-40 ca-initvalues-applied lqd-animations-done">
                        <div className="w-600 max-w-full relative flex flex-col items-center justify-center text-center transition-bg p-10">
                            <div className="w-full flex flex-col items-center text-center">
                                <div className="ld-fancy-heading relative w-auto mb-0/5em bg-orange-100 rounded-100 animation-element">
                                    <h6 className="subhead-head1 ld-fh-element relative m-0 py-10 px-20">Let's choice</h6>
                                </div>
                            </div>
                            <div className="spacer w-full animation-element lqd-unit-animation-done">
                                <div className="w-full h-10"></div>
                            </div>
                            <div className="ld-fancy-heading relative flex max-w-full text-center animation-element lqd-unit-animation-done">
                                <h2 className="subhead-head1 mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2">
                                    <span>Awesome
                                        <mark className="lqd-highlight">
                                            <span className="lqd-highlight-txt">Technology </span>
                                            <span className="lqd-highlight-inner"></span>
                                        </mark> <br />
                                        We Used
                                    </span>
                                </h2>
                            </div>
                        </div>

                        <Throwable />

                    </section>

                    <section className="lqd-section carousel-text pt-90" id="headline">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col col-12 p-0">
                                    <Marquee gradient={false} speed={100} pauseOnClick>
                                        <div className="marquee-text text-12vw uppercase tracking-0/5 text-center">
                                            <h3 className="m-0 text-1em index-since-2008 whitespace-nowrap">since 2008</h3>
                                        </div>
                                        <div className="marquee-text text-12vw uppercase tracking-0/5 text-center">
                                            <h3 className="m-0 text-1em index-since-2008 whitespace-nowrap">since 2008</h3>
                                        </div>
                                        <div className="marquee-text index-since-2008 text-12vw uppercase tracking-0/5 text-center">
                                            <h3 className="m-0 text-1em whitespace-nowrap">since 2008</h3>
                                        </div>
                                    </Marquee>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="lqd-section marketing block pt-70 pb-10" id="process">
                        <div className="container">
                            <div className="row items-center">
                                <div className="col col-12 col-md-6 col-xl-6">
                                    <div className="lqd-imggrp-single block relative perspective" data-hover3d="true"
                                        data-float="ease-in">
                                        <div
                                            className="lqd-imggrp-img-container inline-flex relative items-center justify-center transform-style-3d">
                                            <figure className="w-full relative" data-stacking-factor="1"><img width="995"
                                                height="822" src="/assets/images/home/agile-method-amico-updated.svg"
                                                alt="3D shape marketing" /></figure>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-6 col-xl-5 offset-xl-1">
                                    <div className="contents">
                                        <div className="ld-fancy-heading relative animation-element lqd-unit-animation-done">
                                            <div className="w-full flex flex-col items-start module-content">
                                                <div
                                                    className="ld-fancy-heading relative w-auto mb-0/65em bg-orange-100 rounded-100 animation-element">
                                                    <h6 className="ld-fh-element relative m-0 py-10 px-20">Workflow
                                                    </h6>
                                                </div>
                                            </div>

                                            <h2 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2">
                                                <span>Our </span> <mark className="lqd-highlight"><span
                                                    className="lqd-highlight-txt">Process</span> <span
                                                        className="lqd-highlight-inner"></span></mark>
                                            </h2>
                                        </div>
                                    </div>
                                    <div
                                        className="iconbox flex relative z-2 iconbox-circle iconbox-icon-linked mb-30 text-left lqd-unit-animation-done">
                                        <div className="iconbox-icon-wrap relative mr-25">
                                            <div
                                                className="iconbox-icon-container inline-flex relative z-1 rounded-full items-center justify-center w-40 h-40 text-16 text-primary bg-purple-100">
                                                <i aria-hidden="true" className="lqd-icn-ess icon-num-1 text-1em"></i>
                                            </div>
                                        </div>
                                        <div className="contents">
                                            <h3
                                                className="lqd-iconbox-heading text-20 mt-0/5em mb-0.65em leading-1em">
                                                Consultation</h3>
                                            <p className="text-16 leading-23 text-slate-600"> We hold discussions with you to
                                                understand your goals and requirements thoroughly. </p>
                                        </div>
                                    </div>
                                    <div
                                        className="iconbox flex relative z-2 iconbox-circle iconbox-icon-linked mb-30 text-left lqd-unit-animation-done">
                                        <div className="iconbox-icon-wrap relative mr-25">
                                            <div
                                                className="iconbox-icon-container inline-flex relative z-1 rounded-full items-center justify-center w-40 h-40 text-16 text-primary bg-purple-100">
                                                <i aria-hidden="true" className="lqd-icn-ess icon-num-2 text-1em"></i>
                                            </div>
                                        </div>
                                        <div className="contents">
                                            <h3
                                                className="lqd-iconbox-heading text-20 mt-0/5em mb-0.65em leading-1em">
                                                Planning</h3>
                                            <p className="text-16 leading-23 text-slate-600">After analysis comes planning. We
                                                break down large projects into smaller tasks and prioritise them
                                                accordingly.</p>
                                        </div>
                                    </div>
                                    <div
                                        className="iconbox flex relative z-2 iconbox-circle iconbox-icon-linked mb-30 text-left lqd-unit-animation-done">
                                        <div className="iconbox-icon-wrap relative mr-25 module-icon-box">
                                            <div
                                                className="iconbox-icon-container inline-flex relative z-1 rounded-full items-center justify-center w-40 h-40 text-16 text-primary bg-purple-100">
                                                <i aria-hidden="true" className="lqd-icn-ess icon-num-3 text-1em"></i>
                                            </div>
                                        </div>
                                        <div className="contents">
                                            <h3
                                                className="lqd-iconbox-heading text-20 mt-0/5em mb-0.65em leading-1em">
                                                Design</h3>
                                            <p className="text-16 leading-23 text-slate-600">We craft wireframes, mockups, and
                                                prototypes to ensure alignment with your vision.
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className="iconbox flex relative z-2 iconbox-circle iconbox-icon-linked mb-30 text-left lqd-unit-animation-done">
                                        <div className="iconbox-icon-wrap relative mr-25 module-icon-box">
                                            <div
                                                className="iconbox-icon-container inline-flex relative z-1 rounded-full items-center justify-center w-40 h-40 text-16 text-primary bg-purple-100">
                                                <i aria-hidden="true" className="lqd-icn-ess icon-num-4 text-1em"></i>
                                            </div>
                                        </div>
                                        <div className="contents">
                                            <h3
                                                className="lqd-iconbox-heading text-20 mt-0/5em mb-0.65em leading-1em">
                                                Development</h3>
                                            <p className="text-16 leading-23 text-slate-600">Our team works diligently to bring
                                                your website or app to life, keeping you updated every step of the way.</p>
                                        </div>
                                    </div>
                                    <div
                                        className="iconbox flex relative z-2 iconbox-circle iconbox-icon-linked mb-30 text-left lqd-unit-animation-done">
                                        <div className="iconbox-icon-wrap relative mr-25 module-icon-box">
                                            <div
                                                className="iconbox-icon-container inline-flex relative z-1 rounded-full items-center justify-center w-40 h-40 text-16 text-primary bg-purple-100">
                                                <i aria-hidden="true" className="lqd-icn-ess icon-num-5 text-1em"></i>
                                            </div>
                                        </div>
                                        <div className="contents">
                                            <h3
                                                className="lqd-iconbox-heading text-20 mt-0/5em mb-0.65em leading-1em">
                                                Testing</h3>
                                            <p className="text-16 leading-23 text-slate-600">We conduct comprehensive testing to
                                                ensure the website or app meets your standards and is free of bugs.</p>
                                        </div>
                                    </div>
                                    <div
                                        className="iconbox flex relative z-2 iconbox-circle iconbox-icon-linked mb-30 text-left lqd-unit-animation-done">
                                        <div className="iconbox-icon-wrap relative mr-25 module-icon-box">
                                            <div
                                                className="iconbox-icon-container inline-flex relative z-1 rounded-full items-center justify-center w-40 h-40 text-16 text-primary bg-purple-100">
                                                <i aria-hidden="true" className="lqd-icn-ess icon-num-6 text-1em"></i>
                                            </div>
                                        </div>
                                        <div className="contents">
                                            <h3
                                                className="lqd-iconbox-heading text-20 mt-0/5em mb-0.65em leading-1em">
                                                Launch</h3>
                                            <p className="text-16 leading-23 text-slate-600">We release your website or app to
                                                the world and provide ongoing support to ensure its smooth operation.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="lqd-section blog pt-100 pb-0" id="blog">
                        <div className="container">
                            <div className="row">
                                <div className="col col-12">
                                    <div className="w-full flex flex-col items-center text-center">
                                        <div
                                            className="ld-fancy-heading relative w-auto mb-0/65em bg-orange-100 rounded-100 animation-element">
                                            <h6 className="ld-fh-element relative m-0 py-10 px-20">Blogs</h6>
                                        </div>
                                        <div className="ld-fancy-heading relative w-full lqd-unit-animation-done">
                                            <h2 className="mb-0/75em ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover h2">
                                                <span>Latest</span> <mark className="lqd-highlight"><span
                                                    className="lqd-highlight-txt">Insights</span> <span
                                                        className="lqd-highlight-inner"></span></mark>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12">
                                    <div className="row -mr-15 -ml-15">
                                        {
                                            lastThreeBlogs?.sort((a, b) => {
                                                return (new Date(b.created_at) - new Date(a.created_at))
                                            }).map((data, index) => {
                                                return (
                                                    <div className="col col-12 col-sm-6 col-lg-4 mb-30 px-15 py-0" >
                                                        <Link key={index} to={`/${generateURL(data.title)}`}>
                                                            <article className="lqd-lp relative lqd-lp-style-6 lqd-lp-hover-img-zoom lqd-lp-animate-onhover rounded-4 overflow-hidden text-start post type-post status-publish format-standard has-post-thumbnail hentry category-blog-single tag-beauty tag-travel">
                                                                <div className="lqd-lp-img overflow-hidden">
                                                                    <figure>
                                                                        <img width="720" height="510" src={`../../assets/images/${data.src}`} alt="modernagencywp" />
                                                                    </figure>
                                                                </div>
                                                                <header className="lqd-lp-header p-1rem" style={{ minHeight: "106px" }}>
                                                                    <div className="lqd-lp-meta uppercase font-bold relative z-3">
                                                                        <span className="screen-reader-text">Tags</span>
                                                                        <ul className="lqd-lp-cat lqd-lp-cat-shaped lqd-lp-cat-solid reset-ul inline-ul uppercase tracking-0/1em">
                                                                            <li>                       
                                                                                <a className="rounded-full" href="#" rel="category" style={{ padding: "0px !important" }}>{data.categoryName}</a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <h2 className="entry-title lqd-lp-title mb-0 h5 pt-5">
                                                                        <a className="" rel="bookmark"> {truncateTitle(data.title)}</a>
                                                                    </h2>
                                                                </header>
                                                            </article>
                                                        </Link>
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className="col col-12 col-sm-12 col-lg-12 mb-30 px-15 d-flex justify-end">
                                            <Link to="/blog" data-name="View More" className="button-custom"></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <GetStarted pera="As an award-winning agency, We are consistently challenging ourselves for the betterment. We are your authentic brand." />
                </div>
            </main>
            {/* <FooterPage /> */}
        </>
    )
}